<template>
    <div class="container">

        <!-- Loading overlay -->
        <loading-state
                v-if="loading"
                title="Loading overtime">
        </loading-state>

        <!-- New request link -->
        <div class="card-grid vertical">
            <router-link :to="{name: 'overtime.request'}" class="card card-body card-link horizontal justify-content-start">
                <icon-overtime size="lg"></icon-overtime> Request overtime
            </router-link>
        </div>

        <!-- Request list -->
        <h3 class="mb-2 mt-4">Overtime requests</h3>
        <div class="card-table">
            <div class="card-link-container" v-for="overtime in overtimes" :key="overtime.id">
                <overtime-item
                        :overtime="overtime"
                        @openNoteModal="openOvertimeDeleteModal(overtime.id)">
                </overtime-item>
            </div>
        </div>

        <!-- Empty state -->
        <div class="card card-body" v-if="overtimes.length === 0">
            <empty-state
                    class="pb-2"
                    small
                    title="No overtime taken"
                    subtitle="Request overtime using the link above">
            </empty-state>
        </div>

        <overtime-delete-modal
                :technicianId="user.technician.id"
                :overtimeId="overtimeId">
        </overtime-delete-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props:["overtimeRequested"],

        data() {
            return {
                loading: true
            }
        },

        mounted() {
            this.loadOvertime(this.user.technician.id)
                .then(() =>{
                    this.loading = false;
                });

            if (this.overtimeRequested) {
                this.displayToast({text: 'Overtime request approved', type: 'success'});
            }
        },

        computed: {
            ...mapGetters([
                "overtimeId",
                "overtimes",
                "user"
            ]),
        },

        methods: {
            ...mapActions([
                "displayToast",
                "loadOvertime",
                "setOvertimeId"
            ]),

            /** Open the cancel overtime modal */
            openOvertimeDeleteModal(overtime) {
                this.setOvertimeId(overtime);
                this.$bvModal.show('overtime-delete-modal');
            },
        }
    }
</script>

<style scoped>

</style>